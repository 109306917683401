























































































































import { Utils } from '@/app/infrastructures/misc'
import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import {
  ManageCourierAttendanceListSortFields,
  ManageCourierAttendanceListSortType,
} from '@/app/infrastructures/misc/Constants/tableFields'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import PayrollController from '@/app/ui/controllers/PayrollController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { CourierAttendance } from '@/domain/entities/Payroll'
import MonthPicker from '@/app/ui/components/DateTimePickerV2/MonthPicker/index.vue'
import DropdownMultiSelect from '@/app/ui/views/Driver/components/DropdownMultiSelect/index.vue'
import RouteController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'
import { IOptions } from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'
import ShuttleController from '@/app/ui/controllers/ShuttleController'
import { CourierShuttle } from '@/domain/entities/Shuttle'
import {
  DataObject,
  HeaderObject,
  SortValueObject,
} from '@/app/ui/components/DataTableV2/type'

@Component({
  components: {
    TextInput,
    DateTimePickerV2,
    DataTableV2,
    PaginationNav,
    AscendingIcon,
    LoadingOverlay,
    MonthPicker,
    DropdownMultiSelect,
  },
})
export default class ManageCourierAttendanceList extends Vue {
  controller = PayrollController
  shuttleController = ShuttleController
  routeController = RouteController
  enumSortFields = ManageCourierAttendanceListSortFields
  enumSelectedSort = ManageCourierAttendanceListSortType

  selectedSort: ManageCourierAttendanceListSortType | null = null

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('ID Kurir', '150px'),
    this.headerCellMapper('Nama Kurir', '220px', true, this.enumSortFields.COURIER_NAME, '20px'),
    this.headerCellMapper('Jumlah Hari', '150px'),
    this.headerCellMapper('Pangajuan absensi', '220px', true, this.enumSortFields.ATTENDANCE_SUMMARY, '20px'),
    this.headerCellMapper('Nama Shuttle', '180px'),
    this.headerCellMapper('3LC', '80px'),
    this.headerCellMapper('Atur', '120px'),
    this.headerCellMapper('', ''),
  ]

  tableData: DataObject[][] = []

  parameters = {
    page: 1,
    perPage: 10,
    search: '',
    date: new Date(),
    districtCode: <string[]>[],
    shuttleSearch: '',
    shuttles: <string[]>[],
    sort: <SortValueObject>{
      name: '',
      direction: '',
    },
  }

  perPageOptions = [
    {
      label: '10',
      value: 10,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  originOptions: IOptions[] = []
  shuttleOptions: IOptions[] = []

  get params(): Record<string, string | number | Date> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      date: this.parameters.date || new Date(),
      sortBy: <string>this.parameters.sort.name,
      direction: <string>this.parameters.sort.direction,
      courier_3lc: this.parameters.districtCode.join(','),
      shuttleId: this.parameters.shuttles.join(','),
    }
  }

  get shuttleParams(): Record<string, string | number> {
    return {
      perPage: 50,
      keyword: this.parameters.shuttleSearch,
    }
  }

  created(): void {
    if (!Utils.isSuperAdmin() && !Utils.isFinance()) {
      this.$notify({
        title: 'Forbidden Access',
        text: 'Your Account is not permitted to request to some endpoints',
        type: 'error',
        duration: 5000,
      })

      this.$router.push('/')
    }

    this.fetchList(true)
    this.routeController.getLetterCodes()
    this.shuttleController.getCourierShuttleList(this.shuttleParams)
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.fetchAllCourierAttendance(this.params)
  }

  public onSearch = Utils.debounce(() => {
    this.fetchList(true)
  }, 500)

  public onSearchShuttle = Utils.debounce((val: string) => {
    this.parameters.shuttleSearch = val
    this.shuttleController.getCourierShuttleList(this.shuttleParams)
  }, 500)

  public onSort(sortObj: SortValueObject): void {
    this.parameters.sort = sortObj

    this.fetchList(true)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    withSort?: boolean,
    sortName?: string,
    sortGap?: string
  ): HeaderObject {
    return {
      title: <string>title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
      withSort,
      sortName,
      sortStyle: {
        marginLeft: sortGap,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): DataObject {
    return {
      value: <string>value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.dataCourierAttendance')
  onDataCourierAttendance(data: CourierAttendance[]): void {
    this.tableData = data.map((attendance, index) => {
      return [
        this.tableCellMapper(
          index + 1 + this.parameters.perPage * (this.parameters.page - 1),
          '60px'
        ),
        this.tableCellMapper(<number>attendance.courierId, '150px'),
        this.tableCellMapper(<string>attendance.courierName, '220px'),
        this.tableCellMapper(
          `<div class="text-sm text-gray-4">
            <span class="font-bold">${attendance.attendanceTotal}</span>/
            <span class="font-bold"> ${<number>attendance.dayTotal}</span> hari
          </div>`,
          '150px'
        ),
        this.tableCellMapper(<number>attendance.requestAttendance, '220px'),
        this.tableCellMapper(
          attendance.shuttleId
            ? `${attendance.shuttleId} - ${attendance.shuttleName}`
            : '-',
          '180px'
        ),
        this.tableCellMapper(<string>attendance.courier3lc, '80px'),
        this.tableCellMapper(<number>attendance.courierId, '120px'),
        this.tableCellMapper('', ''),
      ]
    })
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.originOptions = data.map(route => {
      return {
        label: <string>route.lc,
        value: <string>route.lc,
      }
    })
  }

  @Watch('shuttleController.courierShuttleData')
  onShuttleDataChanged(data: CourierShuttle[]): void {
    const uniqueCourierShuttle = data.filter((value, index) => {
      const _value: string = <string>value.shuttleId
      return (
        index ===
        data.findIndex(obj => {
          return obj.shuttleId === _value
        })
      )
    })

    this.shuttleOptions = uniqueCourierShuttle.map(shuttle => {
      return {
        label: `${shuttle.shuttleId} - ${shuttle.shuttleName}`,
        value: <string>shuttle.shuttleId,
      }
    })
  }
}
