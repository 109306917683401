




















import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import CheckBoxMinusIcon from '@/app/ui/assets/check_box_minus_2.vue'

@Component({
  components: {
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    CheckBoxMinusIcon,
  },
})
export default class CheckBox extends Vue {
  @Prop({ default: false }) private isChecked!: boolean
  @Prop({ default: false }) private isIndeterminate!: boolean
  @Prop({ default: false }) private disabled!: boolean

  private onClickCheckBox() {
    if (!this.disabled) {
      this.$emit('click')
    }
  }
}
