import { Pagination } from '@/domain/entities/Pagination'
import { CourierShuttle, CourierShuttles, Shuttle, ShuttleDetail, Shuttles } from '@/domain/entities/Shuttle'
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from 'vuex-module-decorators'
import store from '../store'
import { ShuttlePresenter } from '../presenters/ShuttlePresenter'
import { container } from 'tsyringe'
import { Utils } from '@/app/infrastructures/misc'
import Vue from 'vue'
import { RoutePresenter } from '../presenters/RoutePresenter'
import { OriginRoute } from '@/domain/entities/Route'
import { ManageCourier } from '@/domain/entities/ManageCourier'
import { CreateShuttleRequest } from '@/data/payload/api/ShuttleManagementRequest'

export interface IOption {
  label: string
  value: string | number
}

export interface IShuttleForm {
  shuttleId: string
  shuttleName: string
  shuttleAddress: string
  shuttleMapUrl: string
  shuttleRegion: string
  shuttlePic: {
    picName: string
    picPhoneNumber: string
    picPhoneCode: string
  }[]
  shuttleCycle: {
    id: number
    startTime: Date | null
    endTime: Date | null
  }[]
  shuttleCaptain: IOption | null
  shuttleCourier: ManageCourier[]
}

export interface ShuttleState {
  isLoading: boolean
  paginationData: Pagination
  shuttleData: Shuttle[]
  shuttleForm: IShuttleForm
  districts: IOption[]
  isShuttleIdAlreadyUsed: boolean
  shuttleDetailData: ShuttleDetail
  isEditAddCourier: boolean
  isDeleteShuttleSuccess: boolean
  courierShuttleData: CourierShuttle[]
}

@Module({ namespaced: true, store, name: 'shuttle', dynamic: true })
class ShuttleController extends VuexModule implements ShuttleState {
  private presenter: ShuttlePresenter = container.resolve(ShuttlePresenter)
  private routePresenter: RoutePresenter = container.resolve(RoutePresenter)
  isLoading = false
  paginationData = new Pagination()
  shuttleData: Shuttle[] = []
  shuttleForm: IShuttleForm = {
    shuttleAddress: '',
    shuttleCaptain: null,
    shuttleCourier: [],
    shuttleCycle: [
      {
        id: new Date().getTime() + Math.random(),
        startTime: null,
        endTime: null,
      },
    ],
    shuttleId: '',
    shuttleMapUrl: '',
    shuttleName: '',
    shuttlePic: [
      {
        picName: '',
        picPhoneNumber: '',
        picPhoneCode: '+62',
      },
    ],
    shuttleRegion: 'Default',
  }
  districts: IOption[] = []
  isShuttleIdAlreadyUsed = false
  isSuccessSubmitShuttle = false
  shuttleDetailData = new ShuttleDetail()
  isEditAddCourier = false
  isDeleteShuttleSuccess = false
  courierShuttleData: CourierShuttle[] = []

  @Action({ rawError: true })
  public getShuttleList(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setShuttleData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch List Shuttle Failed',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public resetShuttleForm(): void {
    this.setShuttleForm({
      shuttleAddress: '',
      shuttleCaptain: null,
      shuttleCourier: [],
      shuttleCycle: [
        {
          id: new Date().getTime() + Math.random(),
          endTime: null,
          startTime: null,
        },
      ],
      shuttleId: '',
      shuttleMapUrl: '',
      shuttleName: '',
      shuttlePic: [
        {
          picName: '',
          picPhoneNumber: '',
          picPhoneCode: '+62',
        },
      ],
      shuttleRegion: 'Default',
    })
  }

  @Action({ rawError: true })
  public getDistrictList() {
    this.routePresenter
      .getAllCity()
      .then(cities => {
        this.setDistrictList(cities)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch District List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
  }

  @Action({ rawError: true })
  public createShuttle(payload: CreateShuttleRequest): void {
    this.setLoading(true)

    this.presenter
      .create(payload)
      .then(() => {
        this.setIsSuccessSubmitShuttle(true)
      })
      .catch(error => {
        if (error?.error?.message?.en.includes('Data shuttle already exist')) {
          this.setIsShuttleIdAlreadyUsed(true)
        }
        Vue.notify({
          title: 'Create Shuttle Failed',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateShuttle(params: {
    payload: CreateShuttleRequest
    shuttleId: string
  }): void {
    this.setLoading(true)

    this.presenter
      .update(params.payload, params.shuttleId)
      .then(() => {
        this.setIsSuccessSubmitShuttle(true)
      })
      .catch(error => {
        if (error?.error?.message?.en.includes('Data shuttle already exist')) {
          this.setIsShuttleIdAlreadyUsed(true)
        }
        Vue.notify({
          title: 'Update Shuttle Failed',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getShuttle(shuttleId: string): void {
    this.setLoading(true)
    this.setShuttleDetailData(new ShuttleDetail())

    this.presenter
      .get(shuttleId)
      .then(res => {
        this.setShuttleDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Shuttle Failed',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteShuttle(shuttleId: string): void {
    this.setLoading(true)

    this.presenter
      .delete(shuttleId)
      .then(() => {
        this.setIsDeleteShuttleSuccess(true)
        Vue.notify({
          title: 'Delete Shuttle',
          text: 'Shuttle Deleted Successfully',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Shuttle Failed',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCourierShuttleList(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllCourierShuttle(formattedParams)
      .then(res => {
        this.setCourierShuttleData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch List Courier Shuttle Failed',
          text: [400, 422, 404].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setShuttleData(payload: Shuttles): void {
    this.paginationData = <Pagination>payload.pagination
    this.shuttleData = <Shuttle[]>payload.data
  }

  @Mutation
  public setShuttleForm(payload: IShuttleForm): void {
    this.shuttleForm = payload
  }

  @Mutation
  private setDistrictList(origins: OriginRoute[]): void {
    this.districts = origins.map(origin => ({
      value: origin.letterCode,
      label: origin.letterCode,
    })) as IOption[]
    this.districts.unshift({
      label: 'Semua',
      value: '',
    })
  }

  @Mutation
  public setIsShuttleIdAlreadyUsed(bool: boolean): void {
    this.isShuttleIdAlreadyUsed = bool
  }

  @Mutation
  public setIsSuccessSubmitShuttle(bool: boolean): void {
    this.isSuccessSubmitShuttle = bool
  }

  @Mutation
  public setShuttleDetailData(data: ShuttleDetail): void {
    this.shuttleDetailData = data
  }

  @Mutation
  public setIsEditAddCourier(bool: boolean): void {
    this.isEditAddCourier = bool
  }

  @Mutation
  public setIsDeleteShuttleSuccess(bool: boolean): void {
    this.isDeleteShuttleSuccess = bool
  }

  @Mutation
  public setCourierShuttleData(data: CourierShuttles): void {
    this.courierShuttleData = <CourierShuttle[]>data.data
    this.paginationData = <Pagination>data.pagination
  }
}

export default getModule(ShuttleController)
