export enum ManageCourierAttendanceListSortFields {
  COURIER_NAME = 'fullname',
  ATTENDANCE_SUMMARY = 'attendance_summary'
}

export enum ManageCourierAttendanceListSortType {
  COURIER_NAME_ASC = 'fullname-asc',
  COURIER_NAME_DESC = 'fullname-desc',
}

export enum ManageCourierAttendanceDetailSortFields {
  DATE_PRESENCE = 'date_presence',
}

export enum ManageCourierAttendanceDetailSortType {
  DATE_PRESENCE_ASC = 'date_presence-asc',
  DATE_PRESENCE_DESC = 'date_presence-desc',
}
