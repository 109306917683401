import { ManageCourier } from '@/domain/entities/ManageCourier'
import {
  CreateShuttleRequestInterface,
  ShuttleCycleInterface,
  ShuttlePICInterface,
} from '../contracts/ShuttleManagementRequest'
import { Utils } from '@/app/infrastructures/misc'

export class CreateShuttleRequest implements CreateShuttleRequestInterface {
  public shuttleId: string
  public shuttleName: string
  public shuttleAddress: string
  public shuttleMapUrl: string
  public shuttleCaptain: string
  public shuttleCycle: ShuttleCycleInterface[]
  public shuttleCourier: ManageCourier[]
  public shuttlePic: ShuttlePICInterface[]

  constructor(
    shuttleId: string,
    shuttleName: string,
    shuttleAddress: string,
    shuttleMapUrl: string,
    shuttleCaptain: string,
    shuttleCycle: ShuttleCycleInterface[],
    shuttleCourier: ManageCourier[],
    shuttlePic: ShuttlePICInterface[]
  ) {
    this.shuttleId = shuttleId
    this.shuttleName = shuttleName
    this.shuttleAddress = shuttleAddress
    this.shuttleMapUrl = shuttleMapUrl
    this.shuttleCaptain = shuttleCaptain
    this.shuttleCycle = shuttleCycle
    this.shuttleCourier = shuttleCourier
    this.shuttlePic = shuttlePic
  }

  toPayload(): string {
    const shuttleCourier: {
      courier_id: number
      courier_captain_id?: number
    }[] = this.shuttleCourier.map(courier => {
      return {
        courier_id: <number>courier.courierId,
      }
    })

    if (this.shuttleCaptain) {
      shuttleCourier.unshift({
        courier_id: parseInt(this.shuttleCaptain.split(',')[0]),
        courier_captain_id: parseInt(this.shuttleCaptain.split(',')[1]),
      })
    }

    const payload = {
      shuttle_id: this.shuttleId,
      shuttle_name: this.shuttleName,
      shuttle_address: this.shuttleAddress,
      shuttle_map_url: this.shuttleMapUrl,
      shuttle_region: 'Default',
      shuttle_cycle: this.shuttleCycle.map(cycle => {
        return {
          start_at: Utils.formatDateWithIDLocale(
            <string>cycle.startTime?.toISOString(),
            'HH:mm'
          ),
          end_at: Utils.formatDateWithIDLocale(
            <string>cycle.endTime?.toISOString(),
            'HH:mm'
          ),
        }
      }),
      shuttle_pic: this.shuttlePic.map(pic => {
        return {
          pic_name: pic.picName,
          pic_phone_country_code: pic.picPhoneCode,
          pic_phone_number: pic.picPhoneNumber,
        }
      }),
      shuttle_courier: shuttleCourier
    }

    return JSON.stringify(payload)
  }
}
