function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"manage-courier-attendance-list"},[_c('div',{staticClass:"flex flex-col gap-1"},[_c('div',{staticClass:"text-bgMatterhorn"},[_vm._v("Total pengajuan")]),_c('div',{staticClass:"text-gray-4 font-semibold text-2xl"},[_vm._v(_vm._s(_vm.controller.summaryAttendance)+" absensi")])]),_c('div',{staticClass:"filter"},[_c('div',{staticClass:"input-group w-1/4"},[_c('span',{staticClass:"label"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"type":"search","placeholder":"Cari ID kurir atau nama kurir"},on:{"input":_vm.onSearch},model:{value:(_vm.parameters.search),callback:function ($$v) {_vm.$set(_vm.parameters, "search", $$v)},expression:"parameters.search"}})],1),_c('div',{staticClass:"input-group w-1/3"},[_c('span',{staticClass:"label"},[_vm._v("Filter Shuttle")]),_c('DropdownMultiSelect',{attrs:{"options":_vm.shuttleOptions,"placeholder":"Pilih Shuttle","searchPlaceholder":"Cari Shutle","asyncSearch":true,"isLoading":_vm.shuttleController.isLoading,"useLabel":true},on:{"input":function () {
            _vm.fetchList(true)
          },"search":_vm.onSearchShuttle,"close":_vm.onSearchShuttle},model:{value:(_vm.parameters.shuttles),callback:function ($$v) {_vm.$set(_vm.parameters, "shuttles", $$v)},expression:"parameters.shuttles"}})],1),_c('div',{staticClass:"input-group date-picker w-1/4"},[_c('span',{staticClass:"label"},[_vm._v("Filter per bulan")]),_c('MonthPicker',{attrs:{"maxMonth":new Date()},on:{"input":function () {
            _vm.fetchList()
          }},model:{value:(_vm.parameters.date),callback:function ($$v) {_vm.$set(_vm.parameters, "date", $$v)},expression:"parameters.date"}},[_c('DateTimePickerV2',{attrs:{"formatValue":"MMMM YYYY","disabled":true},model:{value:(_vm.parameters.date),callback:function ($$v) {_vm.$set(_vm.parameters, "date", $$v)},expression:"parameters.date"}})],1)],1),_c('div',{staticClass:"input-group w-1/4"},[_c('span',{staticClass:"label"},[_vm._v("District Code (3LC)")]),_c('DropdownMultiSelect',{attrs:{"options":_vm.originOptions,"placeholder":"Semua","searchPlaceholder":"Cari District Code"},on:{"input":function () {
            _vm.fetchList(true)
          }},model:{value:(_vm.parameters.districtCode),callback:function ($$v) {_vm.$set(_vm.parameters, "districtCode", $$v)},expression:"parameters.districtCode"}})],1)]),_c('div',{staticClass:"manage-courier-attendance-table"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.tableData,"sort":_vm.parameters.sort},on:{"sort":_vm.onSort},scopedSlots:_vm._u([{key:"3",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var data = rest;
return [_c('div',{domProps:{"innerHTML":_vm._s(data.data.value)}})]}},{key:"5",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var data = rest;
return [_c('div',{staticClass:"text-sm word-break text-gray-4"},[_vm._v(_vm._s(data.data.value))])]}},{key:"7",fn:function(ref){
          var rest = objectWithoutProperties( ref, [] );
          var data = rest;
return [_c('router-link',{staticClass:"text-sm text-bgPrimary cursor-pointer font-semibold",attrs:{"to":{
            name: 'ManageCourierAttendanceDetail',
            params: {
              courierId: data.data.value,
            },
          }}},[_vm._v("Lihat Detail")])]}}])})],1),_c('div',{staticClass:"pagination"},[_c('PaginationNav',{attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"perPageOptions":_vm.perPageOptions,"totalItem":_vm.controller.paginationData.total,"enablePerPageOption":""},on:{"onChangeOption":function (val) {
          _vm.parameters.perPage = val
          _vm.fetchList(true)
        },"input":function () {
          _vm.fetchList()
        }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }